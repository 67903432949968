"use client";
import React, { createContext, useEffect, useState } from "react";
import { baseApi as api } from "../services/apiHandler";
import axios from "axios";
import { useRouter } from "next/navigation";
import { removeCookie, setCookie, getCookie } from "@sajilni/utils";
import jwt from "jwt-decode";
interface SuccessData {
  show: boolean;
  message?: string;
}

interface ErrorData {
  show: boolean;
  type: number;
  message?: string;
}

interface ApiContextProps {
  token: string;
  error: ErrorData | null;
  setError: (error: ErrorData | null) => void;
  success: SuccessData | null;
  setSuccess: (success: SuccessData | null) => void;
  getToken: (t: string) => void;
  logout: () => void;
  user: any;
}

const ApiContext = createContext<Partial<ApiContextProps>>({
  token: getCookie("token"),
  error: null,
  setError: () => {},
  success: null,
  setSuccess: () => {},
  getToken: () => {},
  user: { name: "", picture: "" },
});

const ApiProvider = ({ children }) => {
  const [token, setToken] = useState<string>(getCookie("token") || "");
  const [user, setUser] = useState<any>({
    name: "",
    picture: "",
  });

  const [error, setError] = useState<ErrorData | null>(null);
  const [success, setSuccess] = useState<SuccessData | null>(null);
  const router = useRouter();

  useEffect(() => {
    if (getCookie("user")) {
      setUser(JSON.parse(getCookie("user")));
    }
  }, []);
  const getToken = async (t) => {
    axios
      .get(
        `https://event-experience-new.vercel.app/api/auth/one-time-use-token?token=${t}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setToken(res.data.token);
        setUser(jwt(res.data.jwt));

        const user = jwt(res.data.jwt);
        setCookie("token", res.data.token);
        setCookie("user", JSON.stringify(user));
      });
  };

  const logout = () => {
    removeCookie("token");
    removeCookie("user");
    setToken("");
    router.push("/signin");
  };

  useEffect(() => {
    if (token) {
      api.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      });
    }

    api.interceptors.response.use(
      (response) => {
        // Do something with response data
        setError(null);
        return response;
      },
      async (err) => {
        // Do something with response error
        setSuccess(null);
        if (err.message !== "canceled") {
          const originalConfig = err.config;
          if (originalConfig.url !== "login" && err.response) {
            if (
              err.response.status >= 500 &&
              !originalConfig.url.includes("lookups")
            ) {
              setError({
                show: true,
                type: err.response.status >= 500 ? 500 : 403,
                message: err.response.data.error,
              });
            } else if (err.response.status === 401 && !originalConfig._retry) {
              // handle token refresh logic here if required
            } else if (err.response.status === 400) {
              setError({
                show: true,
                type: 400,
                message: err.response.data.error,
              });
            }
          }
          return Promise.reject(err);
        }
      }
    );
  }, [token]);

  return (
    <ApiContext.Provider
      value={{
        token,
        error,
        setError,
        success,
        setSuccess,
        getToken,
        logout,
        user,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export { ApiContext, ApiProvider };
